import { useQuery } from 'villus';
import {
  StoreConfigShippingDetailsDocument,
  type StoreConfigShippingDetailsQuery,
} from '~/graphql/StoreConfigShippingDetails';

export function useStoreConfigShippingDetails() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const state = useCachedSsrRef<StoreConfigShippingDetailsQuery['storeConfig']>(
    cacheParam('store-config-shipping-details'),
    {
      shippingThreshold: null,
      shippingLeadTime: null,
    },
  );

  const { error, execute } = useQuery({
    query: StoreConfigShippingDetailsDocument,
    fetchOnMount: false,
  });
  const { data, status } = useAsyncData(async () => {
    if (state.value.initialized) {
      return state.value;
    }

    const { data } = await execute();
    if (error.value) {
      throw new Error(error.value.message);
    }
    state.value = {
      shippingThreshold: data?.storeConfig?.shippingThreshold,
      shippingLeadTime: data?.storeConfig?.shippingLeadTime,
    };

    return state.value;
  });

  const refs = {
    shippingThreshold: computed(() => data.value.shippingThreshold),
    shippingLeadTime: computed(() => data.value.shippingLeadTime),
  };

  return {
    ...refs,
    status,
  };
}
